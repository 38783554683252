import React, { memo, useState } from "react";
import { Button, Table, CreateAlert } from "../../../../modules";
import { getLocaleKey } from "../../../../languages";
import { TableFilterRangeTimeInput } from "../../../../components/table-filter-inputs";
import { TableFilterInputSelect } from "../../../../components/table-filter-inputs/select";
import _ from "lodash";
import moment from "moment";
import CopyToClipboard from "react-copy-to-clipboard";
import { Icon } from "../../../../components";
import { BankService } from "../../../../services";
import { useSelector } from "../../../../store";
import XLSX from "xlsx";
import { withDIGDeviceWraper } from "..";

export const DeviceHistory = withDIGDeviceWraper(
  memo(() => {
    const listDevice = useSelector((state) => state.device);

    const [forceUpdateTable, setForceUpdateTable] = useState<boolean>(false);
    const [params, setParams] = useState<any>();

    let structure = [
      {
        name: "DEVICE ID",
        key: "deviceId",
        render: (item: any) =>
          item?.deviceId ? (
            <CopyToClipboard
              text={item?.deviceId}
              onCopy={() =>
                CreateAlert({
                  type: "success",
                  message: "Copy Success",
                })
              }
            >
              <span className="copy">
                <Icon.Copy />
                {item?.deviceId}
              </span>
            </CopyToClipboard>
          ) : (
            "---------"
          ),
      },
      {
        name: "BANK ACCOUNT BEFORE",
        key: "accountHolder2",
        render: (item: any) =>
          item?.accountHolder2 ? (
            <CopyToClipboard
              text={item?.accountHolder2}
              onCopy={() =>
                CreateAlert({
                  type: "success",
                  message: "Copy Success",
                })
              }
            >
              <span className="copy">
                <Icon.Copy />
                {item?.accountHolder2}
              </span>
            </CopyToClipboard>
          ) : (
            "---------"
          ),
      },
      {
        name: "BALANCE BEFORE",
        key: "balanceBefore",
        render: (item: any) => (+item.balanceBefore).toLocaleString(getLocaleKey(), { maximumFractionDigits: 4, minimumFractionDigits: 4 }),
      },
      {
        name: "BANK ACCOUNT AFTER",
        key: "accountHolder1",
        render: (item: any) =>
          item?.accountHolder1 ? (
            <CopyToClipboard
              text={item?.accountHolder1}
              onCopy={() =>
                CreateAlert({
                  type: "success",
                  message: "Copy Success",
                })
              }
            >
              <span className="copy">
                <Icon.Copy />
                {item?.accountHolder1}
              </span>
            </CopyToClipboard>
          ) : (
            "---------"
          ),
      },
      {
        name: "BALANCE AFTER",
        key: "balanceAfter",
        render: (item: any) => (+item.balanceAfter).toLocaleString(getLocaleKey(), { maximumFractionDigits: 4, minimumFractionDigits: 4 }),
      },
      {
        name: "TIME",
        key: "created",
        render: (item: any) => {
          return <span>{moment(item?.created).format("DD/MM/y HH:mm:ss")}</span>;
        },
      },
    ];

    const handleExportExcel = async () => {
      return new Promise(async (resolve) => {
        try {
          const response = await BankService.bankDeviceChangeHistory({
            page: 1,
            pageSize: 10000,
            ...params,
          });

          const data = response.data;

          const fileHead = structure.map((v) => v.name);
          const dataExport = [
            fileHead,
            ...data.map((item: any) =>
              structure.map((column, index) => {
                if (!column.key) return "";
                if (column.key === "balanceBefore" || column.key === "balanceAfter")
                  return (+item[column.key]).toLocaleString(getLocaleKey(), { maximumFractionDigits: 4, minimumFractionDigits: 4 });
                if (column.key === "created") return moment(item[column.key]).format("DD/MM/y HH:mm:ss");
                return item[column.key];
              })
            ),
          ];

          const ws = XLSX.utils.aoa_to_sheet(dataExport);
          const wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, "SheetJS");

          const now = new Date();
          XLSX.writeFile(
            wb,
            `Device DIG History ${now.toLocaleDateString().replace(/\//g, "-")} ${now.toLocaleTimeString().replace(/:/g, "-")}.xlsx`,
            { type: "binary" }
          );

          resolve(
            CreateAlert({
              type: "success",
              message: "Export data success.",
            })
          );
        } catch (error: any) {
          resolve(CreateAlert({ type: "danger", message: error.message }));
        }
      });
    };

    return (
      <div className="WithdrawDIG">
        <Button className="mb20" label="Export to Excel" buttonType="success" disabled={!params} onClick={handleExportExcel} />
        <Table
          hasOrderColumn
          hasSearchButton={true}
          doubleScroll
          itemPerPages={10}
          forceUpdateTable={forceUpdateTable}
          filters={[
            // {
            //   name: "Transaction Id",
            //   key: "transactionId",
            //   input: TableFilterInputText,
            // },
            {
              name: "Time",
              key: "created",
              input: (e) => <TableFilterRangeTimeInput {...e} fromKey="fromDate" toKey="toDate" />,
              defaultValue: {
                fromDate: moment().startOf("month").format("YYYY/MM/DD HH:mm:ss"),
                toDate: moment().endOf("day").format("YYYY/MM/DD HH:mm:ss"),
              },
            },
            {
              name: "Device Id",
              key: "deviceId",
              input: (e) => <TableFilterInputSelect isClearable={true} {...e} options={listDevice} isSearchable={false} />,
            },
          ]}
          structure={structure}
          fetchData={async (state) => {
            let params = { ...state };
            if (params["fromDate"]) params["fromDate"] = new Date(params["fromDate"]);
            if (params["toDate"]) params["toDate"] = new Date(params["toDate"]);

            setParams(params);

            return BankService.bankDeviceChangeHistory({
              ...params,
              page: params.pageNumber,
              pageSize: params.limit,
            });
          }}
        />
      </div>
    );
  })
);
