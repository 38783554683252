const defaultState = null;

export const SET_DEVICES = "SET_DEVICES";

export const devicesReducer = (state = defaultState, action: any) => {
  const { type, data, error } = action;
  if (type === SET_DEVICES) {
    if (error) return { error };
    if (data) return data;
  }
  return state;
};
