import _ from "lodash";
import moment from "moment";
import React, { memo, useEffect, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { TableFilterInputText, TableFilterRangeTimeInput } from "../../../components/table-filter-inputs";
import { TableFilterInputSelect } from "../../../components/table-filter-inputs/select";
import { getLocaleKey, translate } from "../../../languages";
import { CreateAlert, Icon, Button, Table } from "../../../modules";
import { AgentService, BankService } from "../../../services";
import { AdminService } from "../../../services/admin";
import XLSX from "xlsx";
import { withReportWraper } from "../wraper";

export const ReportAgent = withReportWraper(
  memo(() => {
    const TypeFilterOptions = [
      { label: "SEND TRANSFER", value: "SEND_TRANSFER" },
      { label: "RECEIVE TRANSFER", value: "RECEIVE_TRANSFER" },
    ];

    const [bankList, setBankList] = useState<any>([]);

    const [forceUpdateTable, setForceUpdateTable] = useState<boolean>(false);
    const [params, setParams] = useState<any>(null);
    const [reports, setReports] = useState<any>(null);

    const sumMiddleware = (func: any) => (item: any, fetchData: any, column: any) => {
      if (item.isSum) {
        if (column.key === "datereport") return "SUM";
        if (column.key === "totaldepositpending")
          return reports?.reduce((sum: any, el: any) => (sum += +el?.totaldeposit - +el?.totaldepositsuccess - +el?.totaldepositreject), 0);
        if (column.key === "totalwithdrawpending")
          return reports?.reduce((sum: any, el: any) => (sum += +el?.totalwithdraw - +el?.totalwithdrawsuccess - +el?.totalwithdrawreject), 0);
        if (column.key === "totalvaluedepositpending")
          return reports?.reduce(
            (sum: any, el: any) =>
              (sum += +el?.totalvaluedeposit - +el?.totalvaluedepositsuccess - +el?.totalvaluedepositreject).toLocaleString(getLocaleKey(), {
                maximumFractionDigits: 4,
                minimumFractionDigits: 4,
              }),
            0
          );
        if (column.key === "totalvaluewithdrawpending")
          return reports?.reduce(
            (sum: any, el: any) =>
              (sum += +el?.totalvaluewithdraw - +el?.totalvaluewithdrawsuccess - +el?.totalvaluewithdrawreject).toLocaleString(getLocaleKey(), {
                maximumFractionDigits: 4,
                minimumFractionDigits: 4,
              }),
            0
          );
        if (
          column.key === "totalvaluedeposit" ||
          column.key === "totalvaluedepositsuccess" ||
          column.key === "totalvaluedepositreject" ||
          column.key === "totalvaluewithdraw" ||
          column.key === "totalvaluewithdrawsuccess" ||
          column.key === "totalvaluewithdrawreject"
        )
          return reports
            ?.reduce((sum: any, el: any) => (sum += +el[column.key]), 0)
            .toLocaleString(getLocaleKey(), { maximumFractionDigits: 4, minimumFractionDigits: 4 });
        return reports?.reduce((sum: any, el: any) => (sum += +el[column.key]), 0);
      }

      return func(item);
    };

    let structure = [
      {
        name: "TIME",
        key: "datereport",
        render: sumMiddleware((item: any) => {
          return <div>{moment(item?.datereport).format("DD/MM/y")}</div>;
        }),
      },
      {
        name: "NAME",
        key: "firstName",
        render: (item: any) =>
          item?.firstName ? (
            <CopyToClipboard
              text={item?.firstName}
              onCopy={() =>
                CreateAlert({
                  type: "success",
                  message: "Copy Success",
                })
              }
            >
              <span className="copy">
                <Icon.Copy />
                {`${item?.firstName}`}
              </span>
            </CopyToClipboard>
          ) : (
            "---------"
          ),
      },
      {
        name: "EMAIl",
        key: "email",
        render: (item: any) =>
          item?.email ? (
            <CopyToClipboard
              text={item?.email}
              onCopy={() =>
                CreateAlert({
                  type: "success",
                  message: "Copy Success",
                })
              }
            >
              <span className="copy">
                <Icon.Copy />
                {`${item?.email}`}
              </span>
            </CopyToClipboard>
          ) : (
            "---------"
          ),
      },
      {
        name: "TOTAL USER ORDER",
        key: "totaluserorder",
        render: sumMiddleware((item: any) => +item?.totaluserorder),
      },
      {
        name: "TOTAL DEPOSIT",
        key: "totaldeposit",
        render: sumMiddleware((item: any) => +item?.totaldeposit),
      },
      {
        name: "TOTAL DEPOSIT PENDING",
        key: "totaldepositpending",
        render: sumMiddleware((item: any) => +item?.totaldeposit - +item?.totaldepositsuccess - +item?.totaldepositreject),
      },
      {
        name: "TOTAL DEPOSIT SUCCESS",
        key: "totaldepositsuccess",
        render: sumMiddleware((item: any) => +item?.totaldepositsuccess),
      },
      {
        name: "TOTAL DEPOSIT REJECT",
        key: "totaldepositreject",
        render: sumMiddleware((item: any) => +item?.totaldepositreject),
      },
      {
        name: "TOTAL BALANCE DEPOSIT",
        key: "totalvaluedeposit",
        render: sumMiddleware((item: any) =>
          (+item?.totalvaluedeposit).toLocaleString(getLocaleKey(), {
            maximumFractionDigits: 4,
            minimumFractionDigits: 4,
          })
        ),
      },
      {
        name: "TOTAL BALANCE DEPOSIT PENDING",
        key: "totalvaluedepositpending",
        render: sumMiddleware((item: any) =>
          (+item?.totalvaluedeposit - +item?.totalvaluedepositsuccess - +item?.totalvaluedepositreject).toLocaleString(getLocaleKey(), {
            maximumFractionDigits: 4,
            minimumFractionDigits: 4,
          })
        ),
      },
      {
        name: "TOTAL BALANCE DEPOSIT SUCCESS",
        key: "totalvaluedepositsuccess",
        render: sumMiddleware((item: any) =>
          (+item?.totalvaluedepositsuccess).toLocaleString(getLocaleKey(), {
            maximumFractionDigits: 4,
            minimumFractionDigits: 4,
          })
        ),
      },
      {
        name: "TOTAL BALANCE DEPOSIT REJECT",
        key: "totalvaluedepositreject",
        render: sumMiddleware((item: any) =>
          (+item?.totalvaluedepositreject).toLocaleString(getLocaleKey(), {
            maximumFractionDigits: 4,
            minimumFractionDigits: 4,
          })
        ),
      },
      // {
      //   name: "TOTAL WITHDRAW",
      //   key: "totalwithdraw",
      //   render: sumMiddleware((item: any) => +item?.totalwithdraw),
      // },
      // {
      //   name: "TOTAL WITHDRAW PENDING",
      //   key: "totalwithdrawpending",
      //   render: sumMiddleware((item: any) => +item?.totalwithdraw - +item?.totalwithdrawsuccess - +item?.totalwithdrawreject),
      // },
      // {
      //   name: "TOTAL WITHDRAW SUCCESS",
      //   key: "totalwithdrawsuccess",
      //   render: sumMiddleware((item: any) => +item?.totalwithdrawsuccess),
      // },
      // {
      //   name: "TOTAL WITHDRAW REJECT",
      //   key: "totalwithdrawreject",
      //   render: sumMiddleware((item: any) => +item?.totalwithdrawreject),
      // },
      // {
      //   name: "TOTAL BALANCE WITHDRAW",
      //   key: "totalvaluewithdraw",
      //   render: sumMiddleware((item: any) =>
      //     (+item?.totalvaluewithdraw).toLocaleString(getLocaleKey(), {
      //       maximumFractionDigits: 4,
      //       minimumFractionDigits: 4,
      //     })),
      // },
      // {
      //   name: "TOTAL BALANCE WITHDRAW PENDING",
      //   key: "totalvaluewithdrawpending",
      //   render: sumMiddleware((item: any) =>
      //     (+item?.totalvaluewithdraw - +item?.totalvaluewithdrawsuccess - +item?.totalvaluewithdrawreject).toLocaleString(getLocaleKey(), {
      //       maximumFractionDigits: 4,
      //       minimumFractionDigits: 4,
      //     })),
      // },
      // {
      //   name: "TOTAL BALANCE WITHDRAW SUCCESS",
      //   key: "totalvaluewithdrawsuccess",
      //   render: sumMiddleware((item: any) =>
      //     (+item?.totalvaluewithdrawsuccess).toLocaleString(getLocaleKey(), {
      //       maximumFractionDigits: 4,
      //       minimumFractionDigits: 4,
      //     })),
      // },
      // {
      //   name: "TOTAL BALANCE WITHDRAW REJECT",
      //   key: "totalvaluewithdrawreject",
      //   render: sumMiddleware((item: any) =>
      //     (+item?.totalvaluewithdrawreject).toLocaleString(getLocaleKey(), {
      //       maximumFractionDigits: 4,
      //       minimumFractionDigits: 4,
      //     })),
      // },
    ];

    const handleExportExcel = async () => {
      return new Promise(async (resolve) => {
        try {
          // const response = await AgentService.getSytemReportAgent({
          //   ...params,
          //   page: params?.pageNumber,
          //   pageSize: 100000,
          // });

          // const data = response.data;

          const fileHead = structure.map((v) => v.name);
          const data = reports.map((item: any) =>
            structure.map((column, index) => {
              if (index === 0) return moment(item?.datereport).format("DD/MM/y");
              if (column.key === "totaldepositpending") return +item?.totaldeposit - +item?.totaldepositsuccess - +item?.totaldepositreject;
              if (column.key === "totalwithdrawpending") return +item?.totalwithdraw - +item?.totalwithdrawsuccess - +item?.totalwithdrawreject;
              if (column.key === "totalvaluedepositpending")
                return (+item?.totalvaluedeposit - +item?.totalvaluedepositsuccess - +item?.totalvaluedepositreject).toLocaleString(getLocaleKey(), {
                  maximumFractionDigits: 4,
                  minimumFractionDigits: 4,
                });
              if (column.key === "totalvaluewithdrawpending")
                return (+item?.totalvaluewithdraw - +item?.totalvaluewithdrawsuccess - +item?.totalvaluewithdrawreject).toLocaleString(
                  getLocaleKey(),
                  {
                    maximumFractionDigits: 4,
                    minimumFractionDigits: 4,
                  }
                );
              if (
                column.key === "totalvaluedeposit" ||
                column.key === "totalvaluedepositsuccess" ||
                column.key === "totalvaluedepositreject" ||
                column.key === "totalvaluewithdraw" ||
                column.key === "totalvaluewithdrawsuccess" ||
                column.key === "totalvaluewithdrawreject"
              )
                return (+item[column.key]).toLocaleString(getLocaleKey(), { maximumFractionDigits: 4, minimumFractionDigits: 4 });
              return item[column.key] || 0;
            })
          );

          const dataExport = [
            fileHead,
            ...data,
            structure.map((column, index) => {
              if (index === 0) return "SUM";
              if (column.key === "datereport") return "SUM";
              if (column.key === "firstName" || column.key === "email") return "---------";
              if (column.key === "totaldepositpending")
                return reports?.reduce((sum: any, el: any) => (sum += +el?.totaldeposit - +el?.totaldepositsuccess - +el?.totaldepositreject), 0);
              if (column.key === "totalwithdrawpending")
                return reports?.reduce((sum: any, el: any) => (sum += +el?.totalwithdraw - +el?.totalwithdrawsuccess - +el?.totalwithdrawreject), 0);
              if (column.key === "totalvaluedepositpending")
                return reports?.reduce(
                  (sum: any, el: any) =>
                    (sum += +el?.totalvaluedeposit - +el?.totalvaluedepositsuccess - +el?.totalvaluedepositreject).toLocaleString(getLocaleKey(), {
                      maximumFractionDigits: 4,
                      minimumFractionDigits: 4,
                    }),
                  0
                );
              if (column.key === "totalvaluewithdrawpending")
                return reports?.reduce(
                  (sum: any, el: any) =>
                    (sum += +el?.totalvaluewithdraw - +el?.totalvaluewithdrawsuccess - +el?.totalvaluewithdrawreject).toLocaleString(getLocaleKey(), {
                      maximumFractionDigits: 4,
                      minimumFractionDigits: 4,
                    }),
                  0
                );
              if (
                column.key === "totalvaluedeposit" ||
                column.key === "totalvaluedepositsuccess" ||
                column.key === "totalvaluedepositreject" ||
                column.key === "totalvaluewithdraw" ||
                column.key === "totalvaluewithdrawsuccess" ||
                column.key === "totalvaluewithdrawreject"
              )
                return reports
                  ?.reduce((sum: any, el: any) => (sum += +el[column.key]), 0)
                  .toLocaleString(getLocaleKey(), { maximumFractionDigits: 4, minimumFractionDigits: 4 });
              return reports?.reduce((sum: any, el: any) => (sum += el[column.key]), 0) || "0";
            }),
          ];

          const ws = XLSX.utils.aoa_to_sheet(dataExport);
          const wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, "SheetJS");

          const now = new Date();
          XLSX.writeFile(wb, `Agent Report ${now.toLocaleDateString().replace(/\//g, "-")} ${now.toLocaleTimeString().replace(/:/g, "-")}.xlsx`, {
            type: "binary",
          });

          resolve(
            CreateAlert({
              type: "success",
              message: "Export data success.",
            })
          );
        } catch (error: any) {
          resolve(CreateAlert({ type: "danger", message: error.message }));
        }
      });
    };

    useEffect(() => {
      setForceUpdateTable((prev) => !prev);
    }, [reports]);

    useEffect(() => {
      BankService.getBankAccountFilter()
        .then((res) => {
          setBankList(
            res?.map((item: any) => ({
              label: `${item?.accountHolder} - ${item?.accountNumber}`,
              value: item?.bankAccountId,
            }))
          );
        })
        .finally(() => setForceUpdateTable((prev) => !prev));
    }, []);

    return (
      <div className="AgentReport">
        <Button className="mb20" label="Export to Excel" buttonType="success" disabled={!params} onClick={handleExportExcel} />
        <Table
          hasSearchButton={true}
          doubleScroll
          itemPerPages={10}
          forceUpdateTable={forceUpdateTable}
          filters={[
            {
              name: "Email",
              key: "email",
              input: TableFilterInputText,
            },
            {
              name: "Time",
              key: "created",
              input: (e) => <TableFilterRangeTimeInput {...e} fromKey="fromDate" toKey="toDate" />,
              defaultValue: {
                fromDate: moment().startOf("month").format("YYYY/MM/DD HH:mm:ss"),
                toDate: moment().endOf("day").format("YYYY/MM/DD HH:mm:ss"),
              },
            },
          ]}
          structure={structure}
          fetchData={async (state) => {
            let params = { ...state };
            if (params["fromDate"]) params["fromDate"] = new Date(params["fromDate"]);
            if (params["toDate"]) params["toDate"] = new Date(params["toDate"]);

            setParams(params);

            return AgentService.getSytemReportAgent({
              ...params,
              page: params.pageNumber,
              pageSize: 9999999999,
            }).then((res) => {
              setReports(res.data);

              return {
                ...res,
                data: [{ isSum: true }, ...res.data],
              };
            });
          }}
        />
      </div>
    );
  })
);
