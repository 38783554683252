import { rolePermissionConfigReducer } from "./../services/config/rolePermission";
import { combineReducers } from "redux";

import { mainReducer } from "../services/main/main.reducer";
import { tradeHistoriesReducer } from "../services/trade/tradeHistories.reducer";
import { symbolsReducer } from "../services/trade/symbols.reducer";
import { countriesReducer } from "../services/main/contries.reducer";
import { coinConversionRateReducer } from "../services/bank/coinConversionRate.reducer";
import { tradeLastResultReducer } from "../services/trade/tradeLastResult.reducer";
import { resultPannelDropletsReducer } from "../services/trade/resultPannelDroplets.reducer";
import { coinsReducer } from "../services/trade/coins.reducer";
import { userReducer } from "../services/user/user.reducer";
import { userExternalWalletsReducer } from "../services/user/userExternalWallets.reducer";
import { userInternalWalletsReducer } from "../services/user/userInternalWallets.reducer";
import { userWalletBalancesReducer } from "../services/user/userWalletBalances.reducer";
import { openedOrdersReducer } from "../services/order/openedOrders.reducer";
import { withdrawConfigReducer } from "../services/config/withdraw.reduce";
import { maintenanceConfigReducer } from "../services/config/maintenance.reduce";
import { rankConfigWithdrawReducer } from "../services/config/rankConfigWithdraw.reduce";
import { detailNewsReducer } from "../services/config/editNews.reduce";
import { maintenanceJobServiceConfigReducer } from "../services/config/maintenanceJobService.reduce";
import { devicesReducer } from "../services/admin/device.reducer";

export const reducers = combineReducers({
  main: mainReducer,

  user: userReducer,
  userExternalWallets: userExternalWalletsReducer,
  userInternalWallets: userInternalWalletsReducer,
  userWalletBalances: userWalletBalancesReducer,

  countries: countriesReducer,

  symbols: symbolsReducer,
  coins: coinsReducer,
  coinConversionRate: coinConversionRateReducer,

  tradeHistories: tradeHistoriesReducer,
  tradeLastResult: tradeLastResultReducer,

  resultPannelDroplets: resultPannelDropletsReducer,

  openedOrders: openedOrdersReducer,

  withdrawConfig: withdrawConfigReducer,
  maintenanceConfig: maintenanceConfigReducer,
  maintenanceJobServiceConfig: maintenanceJobServiceConfigReducer,
  rolePermissionConfig: rolePermissionConfigReducer,
  rankConfigWithdraw: rankConfigWithdrawReducer,
  detailNews: detailNewsReducer,

  device: devicesReducer,
});
