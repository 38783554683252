import React, { memo, useEffect, useState } from "react";
import { Button, Table, CreateAlert } from "../../../../modules";
import { getLocaleKey, translate } from "../../../../languages";
import { TableFilterRangeTimeInput } from "../../../../components/table-filter-inputs";
import { TableFilterInputSelect } from "../../../../components/table-filter-inputs/select";
import _ from "lodash";
import moment from "moment";
import CopyToClipboard from "react-copy-to-clipboard";
import { Icon } from "../../../../components";
import { BankService } from "../../../../services";
import { useSelector } from "../../../../store";
import XLSX from "xlsx";
import { withDIGBankWraper } from "..";
import { AdminService } from "../../../../services/admin";

export const BankMatchingHistory = withDIGBankWraper(
  memo(() => {
    const TypeFilterOptions = [
      { label: "SEND TRANSFER", value: "SEND_TRANSFER" },
      { label: "RECEIVE TRANSFER", value: "RECEIVE_TRANSFER" },
    ];

    const [bankList, setBankList] = useState<any>([]);

    const [forceUpdateTable, setForceUpdateTable] = useState<boolean>(false);
    const [params, setParams] = useState<any>();

    let structure = [
      {
        name: "EMAIL ACTION MATCHING",
        key: "accountHolder",
        render: (item: any) =>
          item?.email ? (
            <CopyToClipboard
              text={item?.email}
              onCopy={() =>
                CreateAlert({
                  type: "success",
                  message: "Copy Success",
                })
              }
            >
              <span className="copy">
                <Icon.Copy />
                {`${item?.email}`}
              </span>
            </CopyToClipboard>
          ) : (
            "---------"
          ),
      },
      {
        name: "BANK ID",
        key: "bankId",
        render: (item: any) =>
          item?.bankId ? (
            <CopyToClipboard
              text={item?.bankId}
              onCopy={() =>
                CreateAlert({
                  type: "success",
                  message: "Copy Success",
                })
              }
            >
              <span className="copy">
                <Icon.Copy />
                {item?.bankId}
              </span>
            </CopyToClipboard>
          ) : (
            "---------"
          ),
      },
      {
        name: "BANK ACCOUNT HOLDER",
        key: "accountHolder",
        render: (item: any) =>
          item?.accountHolder ? (
            <CopyToClipboard
              text={item?.accountHolder}
              onCopy={() =>
                CreateAlert({
                  type: "success",
                  message: "Copy Success",
                })
              }
            >
              <span className="copy">
                <Icon.Copy />
                {`${item?.accountHolder}`}
              </span>
            </CopyToClipboard>
          ) : (
            "---------"
          ),
      },
      {
        name: "BANK ACCOUNT NUMBER",
        key: "accountNumber",
        render: (item: any) =>
          item?.accountNumber ? (
            <CopyToClipboard
              text={item?.accountNumber}
              onCopy={() =>
                CreateAlert({
                  type: "success",
                  message: "Copy Success",
                })
              }
            >
              <span className="copy">
                <Icon.Copy />
                {`${item?.accountNumber}`}
              </span>
            </CopyToClipboard>
          ) : (
            "---------"
          ),
      },
      {
        name: "BALANCE",
        key: "balance",
        render: (item: any) => (+item.balance).toLocaleString(getLocaleKey(), { maximumFractionDigits: 4, minimumFractionDigits: 4 }),
      },
      {
        name: "TIME",
        key: "created",
        render: (item: any) => {
          return <span>{moment(item?.created).format("DD/MM/y HH:mm:ss")}</span>;
        },
      },
    ];

    const handleExportExcel = async () => {
      return new Promise(async (resolve) => {
        try {
          const response = await BankService.getListBankMatching({
            ...params,
            page: params.pageNumber,
            pageSize: 100000,
          });

          const data = response.data;

          const fileHead = structure.map((v) => v.name);
          const dataExport = [
            fileHead,
            ...data.map((item: any) =>
              structure.map((column, index) => {
                if (!column.key) return "";
                if (column.key === "created") return moment(item[column.key]).format("L HH:mm:ss");
                if (column.key === "balance")
                  return (+item[column.key]).toLocaleString(getLocaleKey(), { maximumFractionDigits: 4, minimumFractionDigits: 4 });
                return item[column.key];
              })
            ),
          ];

          const ws = XLSX.utils.aoa_to_sheet(dataExport);
          const wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, "SheetJS");

          const now = new Date();
          XLSX.writeFile(
            wb,
            `Bank Matching History ${now.toLocaleDateString().replace(/\//g, "-")} ${now.toLocaleTimeString().replace(/:/g, "-")}.xlsx`,
            {
              type: "binary",
            }
          );

          resolve(
            CreateAlert({
              type: "success",
              message: "Export data success.",
            })
          );
        } catch (error: any) {
          resolve(CreateAlert({ type: "danger", message: error.message }));
        }
      });
    };

    useEffect(() => {
      BankService.getBankAccountFilter()
        .then((res) => {
          setBankList(
            res?.map((item: any) => ({
              label: `${item?.accountHolder} - ${item?.accountNumber}`,
              value: item?.bankAccountId,
            }))
          );
        })
        .finally(() => setForceUpdateTable((prev) => !prev));
    }, []);

    return (
      <div className="WithdrawDIG">
        <Button className="mb20" label="Export to Excel" buttonType="success" disabled={!params} onClick={handleExportExcel} />
        <Table
          hasOrderColumn
          hasSearchButton={true}
          doubleScroll
          itemPerPages={10}
          forceUpdateTable={forceUpdateTable}
          filters={[
            // {
            //   name: "Transaction Id",
            //   key: "transactionId",
            //   input: TableFilterInputText,
            // },
            {
              name: "Time",
              key: "created",
              input: (e) => <TableFilterRangeTimeInput {...e} fromKey="fromDate" toKey="toDate" />,
              defaultValue: {
                fromDate: moment().startOf("month").format("YYYY/MM/DD HH:mm:ss"),
                toDate: moment().endOf("day").format("YYYY/MM/DD HH:mm:ss"),
              },
            },
            {
              name: "Bank Account",
              key: "bankAccountId",
              input: (e) => <TableFilterInputSelect isClearable={true} {...e} options={bankList} isSearchable={false} />,
            },
          ]}
          structure={structure}
          fetchData={async (state) => {
            let params = { ...state };
            if (params["fromDate"]) params["fromDate"] = new Date(params["fromDate"]);
            if (params["toDate"]) params["toDate"] = new Date(params["toDate"]);

            setParams(params);

            return BankService.getListBankMatching({
              ...params,
              page: params.pageNumber,
              pageSize: params.limit,
            });
          }}
        />
      </div>
    );
  })
);
