import React, { memo, useState } from "react";
import { Button, onError, useForm, Table, CreateAlert, InputWraper } from "../../../modules";
import { translate, getLocaleKey } from "../../../languages";
import { TableFilterInputText, TableFilterRangeTimeInput } from "../../../components/table-filter-inputs";
import { AdminService } from "../../../services/admin";
import { TableFilterInputSelect } from "../../../components/table-filter-inputs/select";
import _ from "lodash";
import moment from "moment";
import { GeneralPopupCompnt } from "../../../components/general-popup-compnt";
import CopyToClipboard from "react-copy-to-clipboard";
import { Icon, InputTextArea } from "../../../components";
import { PopupWraper } from "../../../components/popup";
import { withDIGWraper } from "..";
import XLSX from "xlsx";
import * as Yup from "yup";

export const DepositDIG = withDIGWraper(
  memo(() => {
    const StatusFilterOptions = [
      { label: "SUCCESS", value: "SUCCESS" },
      { label: "REJECT", value: "REJECT" },
      { label: "PENDING", value: "PENDING" },
    ];

    enum EActionType {
      APPROVE = "approve",
      REJECT = "reject",
    }

    const [isShowPopup, setIsShowPopup] = useState<boolean>(false);
    const [isShowQR, setIsShowQR] = useState(null);
    const [actionType, setActionType] = useState<EActionType>();
    const [item, setItem] = useState<any>();
    const [fetchData, setFetchData] = useState<any>();
    const [isLoading, setIsLoading] = useState<any>(false);
    const [forceUpdateTable, setForceUpdateTable] = useState<boolean>(false);
    const [params, setParams] = useState<any>();

    const { getInputProps, handleSubmit, isSubmitting } = useForm({
      enableReinitialize: true,
      structure: [
        {
          name: "reason",
          validate: actionType === EActionType.REJECT && Yup.string().required("Reason is required"),
        },
      ],
      onSubmit: async (values) => {
        return AdminService.rejectDIG(item?.transactionType.toLowerCase(), {
          logId: item?.logId,
          reason: values?.reason,
        })
          .then((res) => {
            fetchData();
            CreateAlert({ message: "Rejected", type: "success" });
          })
          .catch(onError)
          .finally(() => {
            setIsLoading(false);
            setIsShowPopup(false);
          });
      },
    });

    let handleOnClickApproveButton = (item: any, fetchData: any) => {
      setItem(item);
      setFetchData(fetchData);
      setActionType(EActionType.APPROVE);
      setIsShowPopup(true);
      setForceUpdateTable((state) => !state);
    };

    let handleOnClickRejectButton = (item: any, fetchData: any) => {
      setItem(item);
      setFetchData(fetchData);
      setActionType(EActionType.REJECT);
      setIsShowPopup(true);
      setForceUpdateTable((state) => !state);
    };

    let handleOnClickConfirm = (actionType: any, item: any, fetchData: any) => {
      setIsLoading(true);
      if (actionType === EActionType.APPROVE) {
        AdminService.approveDIG(item?.transactionType.toLowerCase(), {
          logId: item?.logId,
        })
          .then((res) => {
            fetchData();
            CreateAlert({ message: "Approved successfully", type: "success" });
          })
          .catch(onError)
          .finally(() => {
            setIsLoading(false);
            setIsShowPopup(false);
          });
      } else handleSubmit();
    };

    let structure = [
      {
        name: "EMAIL",
        key: "email",
        render: (item: any) =>
          item?.email ? (
            <CopyToClipboard
              text={item?.email}
              onCopy={() =>
                CreateAlert({
                  type: "success",
                  message: "Copy Success",
                })
              }
            >
              <span className="copy">
                <Icon.Copy />
                {`${item?.email}`}
              </span>
            </CopyToClipboard>
          ) : (
            "---------"
          ),
      },
      {
        name: "DEVICE ID",
        key: "deviceId",
        render: (item: any) =>
          item?.deviceId ? (
            <CopyToClipboard
              text={item?.deviceId}
              onCopy={() =>
                CreateAlert({
                  type: "success",
                  message: "Copy Success",
                })
              }
            >
              <span className="copy">
                <Icon.Copy />
                {`${item?.deviceId}`}
              </span>
            </CopyToClipboard>
          ) : (
            "---------"
          ),
      },
      {
        name: "BANK ACCOUNT",
        key: "bankAccountHolder",
        render: (item: any) =>
          item?.bankAccountHolder ? (
            <CopyToClipboard
              text={item?.bankAccountHolder}
              onCopy={() =>
                CreateAlert({
                  type: "success",
                  message: "Copy Success",
                })
              }
            >
              <span className="copy">
                <Icon.Copy />
                {`${item?.bankAccountHolder}`}
              </span>
            </CopyToClipboard>
          ) : (
            "---------"
          ),
      },
      {
        name: "TRANSACTION ID",
        key: "transactionId",
        render: (item: any) =>
          item?.transactionId ? (
            <CopyToClipboard
              text={item?.transactionId}
              onCopy={() =>
                CreateAlert({
                  type: "success",
                  message: "Copy Success",
                })
              }
            >
              <span className="copy">
                <Icon.Copy />
                {`${item?.transactionId}`}
              </span>
            </CopyToClipboard>
          ) : (
            "---------"
          ),
      },
      {
        name: "AMOUNT",
        key: "amount",
        render: (item: any) => (+item.amount).toLocaleString(getLocaleKey(), { maximumFractionDigits: 4, minimumFractionDigits: 4 }),
      },
      {
        name: "CURRENCY",
        key: "currency",
        render: (item: any) => "VND",
      },
      {
        name: "TIME",
        key: "created",
        render: (item: any) => {
          return <span>{moment(item?.created).format("DD/MM/y HH:mm:ss")}</span>;
        },
      },
      {
        name: "TIME EXPIRES",
        key: "timeExpires",
        render: (item: any) => {
          return <span>{moment(item?.timeExpires).format("DD/MM/y HH:mm:ss")}</span>;
        },
      },
      {
        name: "APPROVE DATE",
        key: "approvedDate",
        render: (item: any) => {
          return item?.approvedDate ? <span>{moment(item?.approvedDate).format("DD/MM/y HH:mm:ss")}</span> : "---------";
        },
      },
      {
        name: "REJECT REASON",
        key: "rejectReason",
        render: (item: any) =>
          item?.rejectReason
            ? `${item?.rejectReason?.split("_")?.reduce((res: string, item: any, idx: number) => (res = `${res} ${item}`), "")}`
            : "---------",
      },
      {
        name: "STATUS",
        key: "status",
        render: (item: any) => (
          <span className={`status status--${item?.status?.toLowerCase()}`}>{_.startCase(_.toUpper(translate(item?.status)))}</span>
        ),
      },
      {
        name: "ACTIONS",
        render: (item: any, fetchData: any) => {
          return (
            <div className="d-flex flex-column">
              <Button
                type="button"
                buttonType="success-outline"
                label="Approve"
                onClick={() => handleOnClickApproveButton(item, () => fetchData)}
                disabled={item?.status !== "PENDING"}
              />

              <Button
                className="mt10 mb10"
                type="button"
                buttonType="danger-outline"
                label="Reject"
                onClick={() => handleOnClickRejectButton(item, () => fetchData)}
                disabled={item?.status !== "PENDING"}
              />
            </div>
          );
        },
      },
    ];

    const handleExportExcel = async () => {
      return new Promise(async (resolve) => {
        try {
          const response = await AdminService.getListTransactionDIG({
            ...params,
            page: params.pageNumber,
            pageSize: 9999999,
            transactionType: ["DEPOSIT"],
          });

          const data = response.data;

          const fileHead = structure.filter((item) => item.name !== "ACTIONS").map((v) => v.name);
          const dataExport = [
            fileHead,
            ...data
              .filter((item: any) => item.name !== "ACTIONS")
              .map((item: any) =>
                structure.map((column, index) => {
                  if (!column.key) return "";
                  if (column.key === "amount")
                    return (+item[column.key]).toLocaleString(getLocaleKey(), { maximumFractionDigits: 4, minimumFractionDigits: 4 });
                  if (column.key === "currency") return "VND";
                  if (column.key === "created" || column.key === "timeExpires" || column.key === "approvedDate")
                    return moment(item[column.key]).format("L HH:mm:ss");
                  if (column.key === "rejectReason")
                    return `${item[column.key]?.split("_")?.reduce((res: string, item: any, idx: number) => (res = `${res} ${item}`), "")}`;
                  if (column.key === "status") return _.startCase(_.toUpper(translate(item[column.key])));
                  return item[column.key];
                })
              ),
          ];

          const ws = XLSX.utils.aoa_to_sheet(dataExport);
          const wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, "SheetJS");

          const now = new Date();
          XLSX.writeFile(wb, `DIG Deposit ${now.toLocaleDateString().replace(/\//g, "-")} ${now.toLocaleTimeString().replace(/:/g, "-")}.xlsx`, {
            type: "binary",
          });

          resolve(
            CreateAlert({
              type: "success",
              message: "Export data success.",
            })
          );
        } catch (error: any) {
          resolve(CreateAlert({ type: "danger", message: error.message }));
        }
      });
    };

    return (
      <div className="DepositDIG">
        <Button className="mb20" label="Export to Excel" buttonType="success" disabled={!params} onClick={handleExportExcel} />
        <Table
          hasOrderColumn
          doubleScroll
          hasSearchButton={true}
          itemPerPages={10}
          forceUpdateTable={forceUpdateTable}
          filters={[
            {
              name: "Transaction Id",
              key: "transactionId",
              input: TableFilterInputText,
            },
            {
              name: "Time",
              key: "created",
              input: (e) => <TableFilterRangeTimeInput {...e} fromKey="fromDate" toKey="toDate" />,
              defaultValue: {
                fromDate: moment().startOf("month").format("YYYY/MM/DD HH:mm:ss"),
                toDate: moment().endOf("day").format("YYYY/MM/DD HH:mm:ss"),
              },
            },
            {
              name: "Status",
              key: "status",
              input: (e) => <TableFilterInputSelect isClearable={true} {...e} options={StatusFilterOptions} isSearchable={false} />,
              defaultValue: "PENDING",
            },
          ]}
          structure={structure}
          fetchData={async (state) => {
            let params = { ...state };
            if (params["fromDate"]) params["fromDate"] = new Date(params["fromDate"]);
            if (params["toDate"]) params["toDate"] = new Date(params["toDate"]);
            if (params["status"]) params["status"] = [params["status"]];

            setParams(params);

            return AdminService.getListTransactionDIG({
              ...params,
              page: params.pageNumber,
              pageSize: params.limit,
              transactionType: ["DEPOSIT"],
            });
          }}
        />
        {isShowPopup && (
          <GeneralPopupCompnt
            onClose={() => {
              setIsShowPopup(false);
            }}
            onClickConfirm={() => handleOnClickConfirm(actionType, item, fetchData)}
            textButton="OK"
            titlePopup={"Notification!"}
            messagePopup={[
              `Are you sure to `,
              [<span style={{ color: actionType === EActionType.APPROVE ? "#56ca00" : "#ff4c51" }}>{actionType?.toUpperCase()}</span>],
              ` the `,
              [<span style={{ color: "#4680FF" }}>{item?.transactionType}</span>],
              `?`,
            ]}
            isLoading={isSubmitting}
          >
            {actionType === EActionType.REJECT && <InputWraper label={"Reason"} inputProps={getInputProps("reason")} component={InputTextArea} />}
          </GeneralPopupCompnt>
        )}

        {isShowQR && (
          <PopupWraper
            center
            onClose={() => {
              setIsShowQR(null);
            }}
          >
            <img src={isShowQR} alt="" />
          </PopupWraper>
        )}
      </div>
    );
  })
);
