import React, { FC } from "react";
import { RouteComponentProps, NavLink } from "react-router-dom";
import { Routes } from "../../AppRoutes";
import { Icon } from "../../components";
import { useSelector } from "../../store";

export interface IFranchiseWraperProps extends RouteComponentProps {}

export const withFranchiseWraper =
  (Component: FC<IFranchiseWraperProps>) => (props: IFranchiseWraperProps) => {
    const user = useSelector((state) => state.user);
    return (
      <div className="withFranchiseWraper">
        <div className="TabLinks">
          <NavLink to={Routes.franchiseTable.path} exact={true}>
            <Icon.Table />
            Table
          </NavLink>

          <NavLink to={Routes.franchiseTree.path} exact={true}>
            <Icon.Tree />
            Tree
          </NavLink>

          {/* {user?.userRole !== 'AGENT' && <NavLink to={Routes.franchiseLevelSettingHistory.path} exact={true}>
              <Icon.HistoryIcon />
              Level Setting History
          </NavLink>} */}

        {/* <NavLink to={Routes.franchiseCommissionHistory.path} exact={true}>
          <Icon.HistoryIcon />
          Commission History
        </NavLink> */}

          {/* <NavLink to={Routes.franchiseLeader.path} exact={true}>
                    <Icon.Leader />

                    Leader
                </NavLink> */}
        </div>

        <Component {...props} />
      </div>
    );
  };
