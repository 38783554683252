import React, { memo, useState } from "react";
import { Button, onError, useForm, Table, CreateAlert, InputWraper } from "../../../../modules";
import { translate, getLocaleKey } from "../../../../languages";
import { TableFilterRangeTimeInput } from "../../../../components/table-filter-inputs";
import { AdminService } from "../../../../services/admin";
import { TableFilterInputSelect } from "../../../../components/table-filter-inputs/select";
import _ from "lodash";
import moment from "moment";
import { GeneralPopupCompnt } from "../../../../components/general-popup-compnt";
import CopyToClipboard from "react-copy-to-clipboard";
import { Icon, InputSelect, InputText, InputTextArea } from "../../../../components";
import { PopupWraper } from "../../../../components/popup";
import { withDIGDeviceWraper } from "..";
import { store, useSelector } from "../../../../store";
import { BankService } from "../../../../services";
import { SET_DEVICES } from "../../../../services/admin/device.reducer";

export const DeviceList = withDIGDeviceWraper(
  memo(() => {
    const [editData, setEditData] = useState(null);
    const [bankAccountId, setBankAccountId] = useState<string[]>([]);
    const [forceUpdateTable, setForceUpdateTable] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [params, setParams] = useState<any>();
    const [listBankDeviceAvailable, setListBankDeviceAvailable] = useState<any>(null);
    const [loadingBankList, setLoadingBankList] = useState<boolean>(false);

    let structure = [
      {
        name: "DEVICE ID",
        key: "deviceId",
        render: (item: any) =>
          item?.deviceId ? (
            <CopyToClipboard
              text={item?.deviceId}
              onCopy={() =>
                CreateAlert({
                  type: "success",
                  message: "Copy Success",
                })
              }
            >
              <span className="copy">
                <Icon.Copy />
                {item?.deviceId}
              </span>
            </CopyToClipboard>
          ) : (
            "---------"
          ),
      },
      {
        name: "BANK ACCOUNT HOLDER",
        key: "accountHolder",
        render: (item: any) =>
          item?.bankAccounts?.map((el: any) => (
            <CopyToClipboard
              text={el?.accountHolder}
              onCopy={() =>
                CreateAlert({
                  type: "success",
                  message: "Copy Success",
                })
              }
            >
              <span className="copy mb10">
                <Icon.Copy />
                {`${el?.accountHolder}` || "---------"}
              </span>
            </CopyToClipboard>
          )),
      },
      {
        name: "BANK ACCOUNT NUMBER",
        key: "accountNumber",
        render: (item: any) =>
          item?.bankAccounts?.map((el: any) => (
            <CopyToClipboard
              text={el?.accountNumber}
              onCopy={() =>
                CreateAlert({
                  type: "success",
                  message: "Copy Success",
                })
              }
            >
              <span className="copy mb10">
                <Icon.Copy />
                {`${el?.accountNumber}` || "---------"}
              </span>
            </CopyToClipboard>
          )),
      },
      {
        name: "ACTIONS",
        key: "actions",
        render: (item: any) => {
          return (
            <div
              className="action__edit"
              onClick={() => {
                setEditData(item?.deviceId);
                setBankAccountId(
                  item?.bankAccounts?.map((el: any) => ({
                    label: `${el?.accountHolder} - ${el?.accountNumber}`,
                    value: el?.bankAccountId,
                  }))
                );
                BankService.getBankAccountAvailable().then((res) =>
                  setListBankDeviceAvailable(
                    res.map((item: any) => ({
                      label: `${item?.accountHolder} - ${item?.accountNumber}`,
                      value: item?.bankAccountId,
                    }))
                  )
                );
              }}
            >
              <Icon.ActionEditIcon />
            </div>
          );
        },
      },
    ];

    return (
      <div className="DepositDIG">
        <Table
          itemPerPages={1000}
          forceUpdateTable={forceUpdateTable}
          structure={structure}
          fetchData={async (state) => {
            let params = { ...state };
            if (params["fromDate"]) params["fromDate"] = new Date(params["fromDate"]);
            if (params["toDate"]) params["toDate"] = new Date(params["toDate"]);

            setParams(params);

            return BankService.getDeviceTable().then((res) => {
              store.dispatch({
                type: SET_DEVICES,
                data: res.data.map((item: any) => ({
                  label: `${item?.deviceId} - ${item?.accountHolder}`,
                  value: item?.deviceId,
                })),
              });

              return res;
            });
          }}
        />

        {editData && (
          <PopupWraper
            center
            title="EDIT BANK OF DEVICE"
            onClose={() => {
              setEditData(null);
              setListBankDeviceAvailable(null);
            }}
          >
            {!listBankDeviceAvailable ? (
              <div
                style={{
                  textAlign: "center",
                }}
              >
                <Icon.Loading />
              </div>
            ) : (
              <div className="Edit">
                <div className="form-edit">
                  <div className="item">
                    <div className="label mb10">Bank List Available</div>
                    <div className="form-edit_content">
                      {listBankDeviceAvailable.map((item: any) => (
                        <div
                          key={item.id}
                          className="form-edit_content-item"
                          onClick={() => {
                            setBankAccountId((prev: any) => [...prev, item]);
                            setListBankDeviceAvailable((prev: any) => prev.filter((el: any) => el.label !== item.label));
                          }}
                        >
                          {item.label}
                        </div>
                      ))}
                    </div>
                  </div>
                  <Icon.Swap />
                  <div className="item">
                    <div className="label mb10">Bank List Config</div>
                    <div className="form-edit_content">
                      {bankAccountId.map((item: any) => (
                        <div
                          key={item.id}
                          className="form-edit_content-item"
                          onClick={() => {
                            setListBankDeviceAvailable((prev: any) => [...prev, item]);
                            setBankAccountId((prev: any) => prev.filter((el: any) => el.label !== item.label));
                          }}
                        >
                          {item.label}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

                <div>
                  <Button
                    label="Edit"
                    onClick={() => {
                      setLoading(true);
                      return BankService.bankDeviceChange({
                        deviceId: editData,
                        bankAccountIds: bankAccountId.map((item: any) => item.value),
                      })
                        .then(() => {
                          CreateAlert({
                            type: "success",
                            message: "Edit successfully",
                          });
                        })
                        .catch((err: any) => {
                          CreateAlert({
                            type: "danger",
                            message: err.message,
                          });
                        })
                        .finally(() => {
                          setEditData(null);
                          setForceUpdateTable((prev) => !prev);
                          setLoading(false);
                          setListBankDeviceAvailable(null);
                        });
                    }}
                    isMiddle
                    buttonType="success"
                    className="btnBan"
                    type="submit"
                    isLoading={loading}
                    disabled={!bankAccountId}
                  />
                </div>
              </div>
            )}
          </PopupWraper>
        )}
      </div>
    );
  })
);
