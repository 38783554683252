import React, { FC } from "react";
import { RouteComponentProps, NavLink } from "react-router-dom";
import { Routes } from "../../AppRoutes";
import { Icon } from "../../components";

export interface IDIGWraperProps extends RouteComponentProps {}

export const withDIGWraper = (Component: FC<IDIGWraperProps>) => (props: IDIGWraperProps) => {
  return (
    <div className="withTransactionWraper">
      <div className="TabLinks">
        <NavLink to={Routes.depositDIG.path} exact={true}>
          <Icon.Deposit />
          Deposit
        </NavLink>

        {/* <NavLink to={Routes.withdrawDIG.path} exact={true}>
          <Icon.Withdraw />
          Withdraw
        </NavLink> */}

        <NavLink to={Routes.balanceDIG.path} exact={true}>
          <Icon.KYC />
          Balance
        </NavLink>

        <NavLink
          to={Routes.deviceDIG.path}
          exact={true}
          isActive={() => {
            return props?.location?.pathname.includes("/dig/device") || props?.location?.pathname.includes("/dig/device/history");
          }}
        >
          <Icon.Table />
          Device
        </NavLink>

        <NavLink
          to={Routes.bankDIG.path}
          exact={true}
          isActive={() => {
            return (
              props?.location?.pathname.includes("/dig/bank") ||
              props?.location?.pathname.includes("/dig/bank/transfer-history") ||
              props?.location?.pathname.includes("/dig/bank/matching-history")
            );
          }}
        >
          <Icon.Table />
          Bank
        </NavLink>
      </div>

      <Component {...props} />
    </div>
  );
};
