import { RequestMainService } from "../request";

export class AgentService {
  static async getSytemReportAgent(params: any = {}) {
    return RequestMainService.get(`/admin/dig/agent-report-daily`, params).then(({ result }) => ({
      count: result.total,
      data: result.data,
    }));
  }

  static async getSettlementList(params: any = {}) {
    return RequestMainService.get(`/admin/settlement-requests`, params).then(({ result }) => ({
      count: result.total,
      data: result.data,
    }));
  }

  static async setRateSettlement(payload: any) {
    return RequestMainService.post(`/admin/settlement-requests/set-rate`, payload);
  }

  static async acceptSettlement(agentId: string, payload: any) {
    return RequestMainService.post(`/admin/settlement-requests/${agentId}/accept`, payload);
  }

  static async rejectSettlement(agentId: string, payload: any) {
    return RequestMainService.post(`/admin/settlement-requests/${agentId}/reject`, payload);
  }
}
