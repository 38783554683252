import React, { FC } from "react";
import { RouteComponentProps, NavLink } from "react-router-dom";
import { Routes } from "../../../AppRoutes";
import { Icon } from "../../../components";
import { withDIGWraper } from "..";

export interface IDIGDeviceWraperProps extends RouteComponentProps {}

export const withDIGBankWraper = (Component: FC<IDIGDeviceWraperProps>) =>
  withDIGWraper((props: IDIGDeviceWraperProps) => {
    return (
      <div className="withTransactionWraper">
        <div className="TabLinks">
          <NavLink to={Routes.bankDIG.path} exact={true}>
            <Icon.Table />
            List
          </NavLink>

          <NavLink to={Routes.bankTransferHistoryDIG.path} exact={true}>
            <Icon.HistoryIcon />
            Transfer History
          </NavLink>

          <NavLink to={Routes.bankMatchingHistoryDIG.path} exact={true}>
            <Icon.HistoryIcon />
            Matching History
          </NavLink>
        </div>

        <Component {...props} />
      </div>
    );
  });
