import React, { FC } from "react";
import { RouteComponentProps, NavLink } from "react-router-dom";
import { Routes } from "../../../AppRoutes";
import { Icon } from "../../../components";
import { withDIGWraper } from "..";

export interface IDIGDeviceWraperProps extends RouteComponentProps {}

export const withDIGDeviceWraper = (Component: FC<IDIGDeviceWraperProps>) =>
  withDIGWraper((props: IDIGDeviceWraperProps) => {
    return (
      <div className="withTransactionWraper">
        <div className="TabLinks">
          <NavLink to={Routes.deviceDIG.path} exact={true}>
            <Icon.Table />
            List
          </NavLink>

          <NavLink to={Routes.deviceHistoryDIG.path} exact={true}>
            <Icon.HistoryIcon />
            History
          </NavLink>
        </div>

        <Component {...props} />
      </div>
    );
  });
