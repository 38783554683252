import _ from "lodash";
import moment from "moment";
import React, { memo, useState } from "react";
import XLSX from "xlsx";
import * as Yup from "yup";
import { InputNumber, InputTextArea } from "../../../components";
import { GeneralPopupCompnt } from "../../../components/general-popup-compnt";
import { TableFilterInputText, TableFilterRangeTimeInput } from "../../../components/table-filter-inputs";
import { TableFilterInputSelect } from "../../../components/table-filter-inputs/select";
import { getLocaleKey, translate } from "../../../languages";
import { Button, CreateAlert, InputWraper, NumberUtils, ObjectUtils, onError, Table, useForm } from "../../../modules";
import { AgentService } from "../../../services";
import { withReportWraper } from "../wraper";
import { useSelector } from "../../../store";
import { ENetWork } from "../../transaction/transaction/TransactionList";
import { getEnv } from "../../../configs";
import { PopupWraper } from "../../../components/popup";

export const SettlementAgent = withReportWraper(
  memo(() => {
    const StatusFilterOptions = [
      { label: "JUST CREATED", value: "JUST_CREATED" },
      { label: "WAITING CONFIRM", value: "WAITING_CONFIRM" },
      { label: "WAITING APPROVE", value: "WAITING_APPROVE" },
      { label: "PROCESSING", value: "PROCESSING" },
      { label: "SUCCESS", value: "SUCCESS" },
      { label: "REJECT", value: "REJECT" },
    ];

    enum EActionType {
      JUST_CREATED = "just_created",
      APPROVE = "approve",
      REJECT = "reject",
    }

    const coinsState = useSelector((state) => state.coins);
    const coinsOptions = ObjectUtils.getIn(coinsState, "data", [], (arr) =>
      arr.map((item: any) => ({
        label: item.name,
        value: item.coinId,
      }))
    );

    const [isShowPopup, setIsShowPopup] = useState<boolean>(false);
    const [actionType, setActionType] = useState<EActionType>();
    const [item, setItem] = useState<any>();
    const [fetchData, setFetchData] = useState<any>();
    const [isLoading, setIsLoading] = useState<any>(false);
    const [forceUpdateTable, setForceUpdateTable] = useState<boolean>(false);
    const [params, setParams] = useState<any>();

    const { getInputProps, handleSubmit, isSubmitting, resetForm } = useForm({
      enableReinitialize: true,
      structure: [
        {
          name: "rate",
          validate:
            actionType === EActionType.JUST_CREATED &&
            Yup.number().required("Must be provided").integer("Number must be integer").min(1, "Minimum is 1"),
        },
        {
          name: "reason",
          validate: actionType === EActionType.REJECT && Yup.string().required("Reason is required"),
        },
      ],
      onSubmit: async (values) => {
        return actionType === EActionType.JUST_CREATED
          ? AgentService.setRateSettlement({
              rate: +values?.rate,
              settlementRequestId: item?.settlementRequestId,
            })
              .then((res) => {
                fetchData();
                CreateAlert({ message: "Set Rate Successfully", type: "success" });
              })
              .catch(onError)
              .finally(() => {
                setIsLoading(false);
                setIsShowPopup(false);
                resetForm();
              })
          : AgentService.rejectSettlement(item?.settlementRequestId, {
              rejectReason: values?.reason,
            })
              .then((res) => {
                fetchData();
                CreateAlert({ message: "Rejected", type: "success" });
              })
              .catch(onError)
              .finally(() => {
                setIsLoading(false);
                setIsShowPopup(false);
                resetForm();
              });
      },
    });

    let handleOnClickSetRateButton = (item: any, fetchData: any) => {
      setItem(item);
      setFetchData(fetchData);
      setActionType(EActionType.JUST_CREATED);
      setIsShowPopup(true);
      setForceUpdateTable((state) => !state);
    };

    let handleOnClickApproveButton = (item: any, fetchData: any) => {
      setItem(item);
      setFetchData(fetchData);
      setActionType(EActionType.APPROVE);
      setIsShowPopup(true);
      setForceUpdateTable((state) => !state);
    };

    let handleOnClickRejectButton = (item: any, fetchData: any) => {
      setItem(item);
      setFetchData(fetchData);
      setActionType(EActionType.REJECT);
      setIsShowPopup(true);
      setForceUpdateTable((state) => !state);
    };

    let handleOnClickConfirm = (actionType: any, item: any, fetchData: any) => {
      setIsLoading(true);
      if (actionType === EActionType.APPROVE) {
        AgentService.acceptSettlement(item?.settlementRequestId, {
          logId: item?.logId,
        })
          .then((res) => {
            fetchData();
            CreateAlert({ message: "Approved Successfully", type: "success" });
          })
          .catch(onError)
          .finally(() => {
            setIsLoading(false);
            setIsShowPopup(false);
            resetForm();
          });
      } else handleSubmit();
    };

    let structure = [
      {
        name: "EMAIL",
        key: "email",
        render: (item: any) => {
          return <span className="email">{item.email}</span>;
        },
        copyable: (item: any) => item?.email,
      },
      {
        name: "ADDRESS",
        key: "address",
        render: (item: any) =>
          (item?.address ?? "") === "" ? (
            "N/A"
          ) : (
            <span title={item?.address}>
              {item?.address.length > 12 ? `${item?.address.slice(0, 6)}...${item?.address.slice(-6)}` : item?.address}
            </span>
          ),
        copyable: (item: any) => item?.address,
      },
      {
        name: "AMOUNT",
        key: "amount",
        render: (item: any) => {
          return <span className="textSuccess">{NumberUtils.toCurrency(+item?.amount, "", "")}</span>;
        },
      },
      {
        name: "CURRENT",
        key: "coinId",
        render: (item: any) => {
          const coin = coinsOptions.find((v: any) => v.value === item.coinId);
          let coinAvatar;
          switch (coin?.value) {
            case 3: {
              coinAvatar = "usdt.png";
              break;
            }
            case 6: {
              coinAvatar = "usd.png";
              break;
            }
            case 8: {
              coinAvatar = "vlt.png";
              break;
            }
            case 10: {
              coinAvatar = "vnd.png";
              break;
            }
          }
          return (
            <div className="coin">
              <img className="coin-avatar" src={`/assets/images/coins/${coinAvatar}`} alt="" />
              <span className="coin-label">{ObjectUtils.getIn(coin, "label", "--")}</span>
            </div>
          );
        },
      },
      {
        name: "RATE",
        key: "rate",
        render: (item: any) => (item?.rate ? NumberUtils.toFormatNumber(+item?.rate, 0, false) : "---------"),
      },
      {
        name: "AMOUNT TRANSFER",
        key: "amountByRate",
        render: (item: any) => {
          return <span className="textSuccess">${NumberUtils.toFormatNumber(+item?.amountByRate, +getEnv("NUMBER_DECIMAL_DISPLAY"))}</span>;
        },
      },
      {
        name: "TIME",
        key: "created",
        render: (item: any) => <span>{moment(item?.created).format("DD/MM/y HH:mm:ss")}</span>,
      },
      {
        name: "APPROVE DATE",
        key: "approvedDate",
        render: (item: any) => {
          return item?.approvedDate ? <span>{moment(item?.approvedDate).format("DD/MM/y HH:mm:ss")}</span> : "---------";
        },
      },
      {
        name: "REJECT REASON",
        key: "reason",
        render: (item: any) =>
          item?.reason ? `${item?.reason?.split("_")?.reduce((res: string, item: any, idx: number) => (res = `${res} ${item}`), "")}` : "---------",
      },
      {
        name: "TxHASH",
        key: "description",
        render: (item: any) => {
          const hash = ObjectUtils.getIn(item, "transactionHash");
          if (hash) {
            switch (item.network) {
              case ENetWork.BEP20: {
                return (
                  <a href={`${getEnv("BSC_SCAN")}${hash}`} target="__blank">
                    Click me
                  </a>
                );
              }
              case ENetWork.TRC20: {
                return (
                  <a href={`${getEnv("TRON_SCAN")}${hash}`} target="__blank">
                    Click me
                  </a>
                );
              }
              default: {
                return (
                  <a href={`${getEnv("BSC_SCAN")}${hash}`} target="__blank">
                    Click me
                  </a>
                );
              }
            }
          }
          return "--";
        },
      },
      {
        name: "STATUS",
        key: "status",
        render: (item: any) => (
          <span className={`status status--${item?.status?.toLowerCase()}`}>{_.startCase(_.toUpper(translate(item?.status)))}</span>
        ),
      },
      {
        name: "ACTIONS",
        render: (item: any, fetchData: any) => {
          return (
            <div className="d-flex flex-column">
              <Button
                type="button"
                buttonType="success-outline"
                label={item?.status === "JUST_CREATED" ? "Set Rate" : "Approve"}
                onClick={() =>
                  item?.status === "JUST_CREATED"
                    ? handleOnClickSetRateButton(item, () => fetchData)
                    : handleOnClickApproveButton(item, () => fetchData)
                }
                disabled={
                  item?.status === "WAITING_CONFIRM" || item?.status === "SUCCESS" || item?.status === "REJECT" || item?.status === "PROCESSING"
                }
              />

              <Button
                className="mt10 mb10"
                type="button"
                buttonType="danger-outline"
                label="Reject"
                onClick={() => handleOnClickRejectButton(item, () => fetchData)}
                disabled={item?.status === "SUCCESS" || item?.status === "REJECT" || item?.status === "PROCESSING"}
              />
            </div>
          );
        },
      },
    ];

    const handleExportExcel = async () => {
      return new Promise(async (resolve) => {
        try {
          const response = await AgentService.getSettlementList({
            ...params,
            page: params.pageNumber,
            pageSize: 9999999,
          });

          const data = response.data;

          const fileHead = structure.filter((item) => item.name !== "ACTIONS").map((v) => v.name);
          const dataExport = [
            fileHead,
            ...data
              .filter((item: any) => item.name !== "ACTIONS")
              .map((item: any) =>
                structure.map((column, index) => {
                  if (!column.key) return "";
                  if (column.key === "rate") return item?.rate ? NumberUtils.toFormatNumber(+item?.rate, 0, false) : "---------";
                  if (column.key === "amount") return NumberUtils.toCurrency(+item?.amount, "", "");
                  if (column.key === "amountByRate") return `$${NumberUtils.toFormatNumber(+item?.amountByRate, +getEnv("NUMBER_DECIMAL_DISPLAY"))}}`;
                  if (column.key === "currency") return "VND";
                  if (column.key === "created" || column.key === "approvedDate") return moment(item[column.key]).format("L HH:mm:ss");
                  if (column.key === "reason")
                    return `${item[column.key]?.split("_")?.reduce((res: string, item: any, idx: number) => (res = `${res} ${item}`), "")}`;
                  if (column.key === "status") return _.startCase(_.toUpper(translate(item[column.key])));
                  if (column.key === "transactionHash") {
                    const hash = ObjectUtils.getIn(item, "transactionHash");
                    if (hash) {
                      switch (item.network) {
                        case ENetWork.BEP20: {
                          return `${getEnv("BSC_SCAN")}${hash}`;
                        }
                        case ENetWork.TRC20: {
                          return `${getEnv("TRON_SCAN")}${hash}`;
                        }
                        default: {
                          return "";
                        }
                      }
                    }
                  }
                  return item[column.key];
                })
              ),
          ];

          const ws = XLSX.utils.aoa_to_sheet(dataExport);
          const wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, "SheetJS");

          const now = new Date();
          XLSX.writeFile(wb, `Settlement ${now.toLocaleDateString().replace(/\//g, "-")} ${now.toLocaleTimeString().replace(/:/g, "-")}.xlsx`, {
            type: "binary",
          });

          resolve(
            CreateAlert({
              type: "success",
              message: "Export data success.",
            })
          );
        } catch (error: any) {
          resolve(CreateAlert({ type: "danger", message: error.message }));
        }
      });
    };

    return (
      <div className="Settlement">
        <Button className="mb20" label="Export to Excel" buttonType="success" disabled={!params} onClick={handleExportExcel} />
        <Table
          hasOrderColumn
          doubleScroll
          hasSearchButton={true}
          itemPerPages={10}
          forceUpdateTable={forceUpdateTable}
          filters={[
            {
              name: "Email",
              key: "email",
              input: TableFilterInputText,
            },
            {
              name: "Time",
              key: "created",
              input: (e) => <TableFilterRangeTimeInput {...e} fromKey="fromDate" toKey="toDate" />,
              defaultValue: {
                fromDate: moment().startOf("month").format("YYYY/MM/DD HH:mm:ss"),
                toDate: moment().endOf("day").format("YYYY/MM/DD HH:mm:ss"),
              },
            },
            {
              name: "Status",
              key: "status",
              input: (e) => <TableFilterInputSelect isClearable={true} {...e} options={StatusFilterOptions} isSearchable={false} />,
            },
          ]}
          structure={structure}
          fetchData={async (state) => {
            let params = { ...state };
            if (params["fromDate"]) params["fromDate"] = new Date(params["fromDate"]);
            if (params["toDate"]) params["toDate"] = new Date(params["toDate"]);
            if (params["status"]) params["status"] = [params["status"]];

            setParams(params);

            return AgentService.getSettlementList({
              page: params.pageNumber,
              pageSize: params.limit,
              ...params,
            });
          }}
        />
        {isShowPopup && actionType !== EActionType.JUST_CREATED && (
          <GeneralPopupCompnt
            onClose={() => {
              setIsShowPopup(false);
              resetForm();
            }}
            onClickConfirm={() => handleOnClickConfirm(actionType, item, fetchData)}
            textButton="OK"
            titlePopup={"Notification!"}
            messagePopup={[
              `Are you sure to `,
              [<span style={{ color: actionType === EActionType.APPROVE ? "#56ca00" : "#ff4c51" }}>{actionType?.toUpperCase()}</span>],
              ` the `,
              [<span style={{ color: "#4680FF" }}>settlement</span>],
              `?`,
            ]}
            isLoading={isSubmitting}
          >
            {actionType === EActionType.REJECT && <InputWraper label={"Reason"} inputProps={getInputProps("reason")} component={InputTextArea} />}
          </GeneralPopupCompnt>
        )}

        {isShowPopup && actionType === EActionType.JUST_CREATED && (
          <PopupWraper
            center
            title="SET RATE"
            onClose={() => {
              setIsShowPopup(false);
              resetForm();
            }}
            className="Set-rate"
          >
            <InputWraper label={"Rate"} inputProps={getInputProps("rate")} component={InputNumber} />
            <Button
              label="Confirm"
              isMiddle
              buttonType="success"
              className="btnBan"
              type="submit"
              isLoading={isSubmitting}
              onClick={() => handleOnClickConfirm(actionType, item, fetchData)}
            />
          </PopupWraper>
        )}
      </div>
    );
  })
);
