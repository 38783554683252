import React, { memo, useEffect, useState } from "react";
import { Table, CreateAlert, NumberUtils, Button } from "../../../modules";
import { translate, getLocaleKey } from "../../../languages";
import { TableFilterInputText, TableFilterRangeTimeInput } from "../../../components/table-filter-inputs";
import { AdminService } from "../../../services/admin";
import { TableFilterInputSelect } from "../../../components/table-filter-inputs/select";
import _ from "lodash";
import moment from "moment";
import CopyToClipboard from "react-copy-to-clipboard";
import { Icon } from "../../../components";
import { PopupWraper } from "../../../components/popup";
import { useSelector } from "../../../store";
import { withDIGWraper } from "..";
import XLSX from "xlsx";
import { BankService } from "../../../services";

export const BalanceDIG = withDIGWraper(
  memo(() => {
    const TypeFilterOptions = [
      { label: "DEPOSIT", value: "DEPOSIT" },
      { label: "WITHDRAW", value: "WITHDRAW" },
      { label: "SEND TRANSFER", value: "SEND_TRANSFER" },
      { label: "RECEIVE TRANSFER", value: "RECEIVE_TRANSFER" },
      { label: "INIT", value: "INIT" },
    ];

    const listDevice = useSelector((state) => state.device);

    const [bankList, setBankList] = useState<any>([]);

    const [forceUpdateTable, setForceUpdateTable] = useState<boolean>(false);
    const [params, setParams] = useState<any>();
    const [isShowQR, setIsShowQR] = useState(null);

    let structure = [
      {
        name: "TRANSACTION ID",
        key: "transactionCode",
        render: (item: any) =>
          item?.transactionCode ? (
            <CopyToClipboard
              text={item?.transactionCode}
              onCopy={() =>
                CreateAlert({
                  type: "success",
                  message: "Copy Success",
                })
              }
            >
              <span className="copy">
                <Icon.Copy />
                {`${item?.transactionCode}`}
              </span>
            </CopyToClipboard>
          ) : (
            "---------"
          ),
      },
      {
        name: "DEVICE ID",
        key: "deviceId",
        className: "device-id",
        render: (item: any) =>
          item?.deviceId ? (
            <CopyToClipboard
              text={item?.deviceId}
              onCopy={() =>
                CreateAlert({
                  type: "success",
                  message: "Copy Success",
                })
              }
            >
              <span className="copy">
                <Icon.Copy />
                {item?.deviceId}
              </span>
            </CopyToClipboard>
          ) : (
            "---------"
          ),
      },
      {
        name: "BANK ACCOUNT",
        key: "accountHolder",
        render: (item: any) =>
          item?.accountHolder ? (
            <CopyToClipboard
              text={`${item?.accountHolder} - ${item?.accountNumber}`}
              onCopy={() =>
                CreateAlert({
                  type: "success",
                  message: "Copy Success",
                })
              }
            >
              <span className="copy">
                <Icon.Copy />
                {`${item?.accountHolder} - ${item?.accountNumber}`}
              </span>
            </CopyToClipboard>
          ) : (
            "---------"
          ),
      },
      {
        name: "AMOUNT",
        key: "amount",
        render: (item: any) => (
          <span
            className={
              item?.transactionType === "DEPOSIT" || item?.transactionType === "RECEIVE_TRANSFER" || item?.transactionType === "INIT"
                ? "textSuccess"
                : "textDanger"
            }
          >
            {item?.transactionType === "DEPOSIT" || item?.transactionType === "RECEIVE_TRANSFER" || item?.transactionType === "INIT" ? "+" : "-"}
            {(+item.amount).toLocaleString(getLocaleKey(), { maximumFractionDigits: 4, minimumFractionDigits: 4 })}
          </span>
        ),
      },
      {
        name: "BALANCE BEFORE",
        key: "balanceBefore",
        render: (item: any) => (+item.balanceBefore).toLocaleString(getLocaleKey(), { maximumFractionDigits: 4, minimumFractionDigits: 4 }),
      },
      {
        name: "BALANCE AFTER",
        key: "balanceAfter",
        render: (item: any) => (+item.balanceAfter).toLocaleString(getLocaleKey(), { maximumFractionDigits: 4, minimumFractionDigits: 4 }),
      },
      {
        name: "TIME",
        key: "created",
        render: (item: any) => {
          return <span>{moment(item?.created).format("DD/MM/y HH:mm:ss")}</span>;
        },
      },
      {
        name: "TYPE",
        key: "transactionType",
        render: (item: any) => (
          <span className={`type type--${item?.transactionType?.toLowerCase()}`}>{_.startCase(_.toUpper(translate(item?.transactionType)))}</span>
        ),
      },
    ];

    const handleExportExcel = async () => {
      return new Promise(async (resolve) => {
        try {
          const response = await AdminService.getListBalanceDIG({
            page: params.pageNumber,
            pageSize: 100000,
            ...params,
          });

          const data = response.data;

          const fileHead = structure.map((v) => v.name);
          const dataExport = [
            fileHead,
            ...data.map((item: any) =>
              structure.map((column, index) => {
                if (!column.key) return "";
                if (column.key === "created") return moment(item[column.key]).format("L HH:mm:ss");
                if (column.key === "amount")
                  return `${
                    item?.transactionType === "DEPOSIT" || item?.transactionType === "RECEIVE_TRANSFER" || item?.transactionType === "INIT"
                      ? "+"
                      : "-"
                  } ${(+item.amount).toLocaleString(getLocaleKey(), {
                    maximumFractionDigits: 4,
                    minimumFractionDigits: 4,
                  })}`;
                if (column.key === "balanceBefore" || column.key === "balanceAfter")
                  return (+item[column.key]).toLocaleString(getLocaleKey(), { maximumFractionDigits: 4, minimumFractionDigits: 4 });
                if (column.key === "transactionType") return _.startCase(_.toUpper(translate(item?.transactionType)));
                return item[column.key];
              })
            ),
          ];

          const ws = XLSX.utils.aoa_to_sheet(dataExport);
          const wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, "SheetJS");

          const now = new Date();
          XLSX.writeFile(wb, `DIG Balances ${now.toLocaleDateString().replace(/\//g, "-")} ${now.toLocaleTimeString().replace(/:/g, "-")}.xlsx`, {
            type: "binary",
          });

          resolve(
            CreateAlert({
              type: "success",
              message: "Export data success.",
            })
          );
        } catch (error: any) {
          resolve(CreateAlert({ type: "danger", message: error.message }));
        }
      });
    };

    useEffect(() => {
      BankService.getBankAccountFilter()
        .then((res) => {
          setBankList(
            res?.map((item: any) => ({
              label: `${item?.accountHolder} - ${item?.accountNumber}`,
              value: item?.bankAccountId,
            }))
          );
        })
        .finally(() => setForceUpdateTable((prev) => !prev));
    }, []);

    return (
      <div className="BalanceDIG">
        <Button className="mb20" label="Export to Excel" buttonType="success" disabled={!params} onClick={handleExportExcel} />
        <Table
          hasOrderColumn
          hasSearchButton={true}
          itemPerPages={10}
          forceUpdateTable={forceUpdateTable}
          filters={[
            // {
            //   name: "Transaction Id",
            //   key: "transactionId",
            //   input: TableFilterInputText,
            // },
            {
              name: "Time",
              key: "created",
              input: (e) => <TableFilterRangeTimeInput {...e} fromKey="fromDate" toKey="toDate" />,
              defaultValue: {
                fromDate: moment().startOf("month").format("YYYY/MM/DD HH:mm:ss"),
                toDate: moment().endOf("day").format("YYYY/MM/DD HH:mm:ss"),
              },
            },
            {
              name: "Device Id",
              key: "deviceId",
              input: (e) => <TableFilterInputSelect isClearable={true} {...e} options={listDevice} isSearchable={false} />,
            },
            {
              name: "Bank Account",
              key: "bankAccountId",
              input: (e) => <TableFilterInputSelect isClearable={true} {...e} options={bankList} isSearchable={false} />,
            },
            {
              name: "Type",
              key: "transactionType",
              input: (e) => <TableFilterInputSelect isClearable={true} {...e} options={TypeFilterOptions} isSearchable={false} />,
            },
          ]}
          structure={structure}
          fetchData={async (state) => {
            let params = { ...state };
            if (params["fromDate"]) params["fromDate"] = new Date(params["fromDate"]);
            if (params["toDate"]) params["toDate"] = new Date(params["toDate"]);
            if (params["transactionType"]) params["transactionType"] = [params["transactionType"]];

            setParams(params);

            return AdminService.getListBalanceDIG({
              page: params.pageNumber,
              pageSize: params.limit,
              ...params,
            });
          }}
        />

        {isShowQR && (
          <PopupWraper
            center
            onClose={() => {
              setIsShowQR(null);
            }}
          >
            <img src={isShowQR} alt="" />
          </PopupWraper>
        )}
      </div>
    );
  })
);
