import React, { memo, useEffect, useState } from "react";
import { Button, useForm, Table, CreateAlert, InputWraper, onError } from "../../../../modules";
import { translate, getLocaleKey } from "../../../../languages";
import _ from "lodash";
import CopyToClipboard from "react-copy-to-clipboard";
import { Icon, InputNumber, InputSelect } from "../../../../components";
import { PopupWraper } from "../../../../components/popup";
import { withDIGBankWraper } from "..";
import { BankService } from "../../../../services";
import * as Yup from "yup";
import { GeneralPopupCompnt } from "../../../../components/general-popup-compnt";

export const BankList = withDIGBankWraper(() => {
  const [bankAccountFrom, setBankAccountFrom] = useState<any>(null);
  const [bankAccountTo, setBankAccountTo] = useState<any>(null);
  const [bankList, setBankList] = useState<any>(null);
  const [forceUpdateTable, setForceUpdateTable] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [params, setParams] = useState<any>();
  const [dataMatchingBank, setDataMatchingBank] = useState<any>(null);

  const { getInputProps, handleSubmit, resetForm } = useForm({
    enableReinitialize: true,
    structure: [
      {
        name: "amount",
        validate:
          bankAccountFrom &&
          Yup.number()
            .required(translate("Must be provided"))
            .min(1, "Minimum is transfer")
            .max(bankAccountFrom.balance, "The amount in the asset is not enough"),
      },
    ],
    onSubmit: async (values) => {
      setLoading(true);
      return BankService.bankTransfer({
        bankAccountId: bankAccountTo,
        bankAccountIdFrom: bankAccountFrom?.bankAccountId,
        amount: +values?.amount,
      })
        .then((res) => {
          CreateAlert({
            type: "success",
            message: "Transfer successfully",
          });
        })
        .catch(onError)
        .finally(() => {
          setForceUpdateTable((prev) => !prev);
          setLoading(false);
          setBankAccountFrom(null);
          setBankAccountTo(null);
          resetForm();
        });
    },
  });

  let structure = [
    {
      name: "BANK ID",
      key: "bankId",
      render: (item: any) =>
        item?.bankId ? (
          <CopyToClipboard
            text={item?.bankId}
            onCopy={() =>
              CreateAlert({
                type: "success",
                message: "Copy Success",
              })
            }
          >
            <span className="copy">
              <Icon.Copy />
              {item?.bankId}
            </span>
          </CopyToClipboard>
        ) : (
          "---------"
        ),
    },
    {
      name: "ACCOUNT HOLDER",
      key: "accountHolder",
      render: (item: any) => (
        <CopyToClipboard
          text={item?.accountHolder}
          onCopy={() =>
            CreateAlert({
              type: "success",
              message: "Copy Success",
            })
          }
        >
          <span className="copy">
            <Icon.Copy />
            {`${item?.accountHolder}` || "---------"}
          </span>
        </CopyToClipboard>
      ),
    },
    {
      name: "ACCOUNT NUMBER",
      key: "accountNumber",
      render: (item: any) => (
        <CopyToClipboard
          text={item?.accountNumber}
          onCopy={() =>
            CreateAlert({
              type: "success",
              message: "Copy Success",
            })
          }
        >
          <span className="copy">
            <Icon.Copy />
            {`${item?.accountNumber}` || "---------"}
          </span>
        </CopyToClipboard>
      ),
    },
    {
      name: "BANK NAME",
      key: "bankName",
    },
    {
      name: "BALANCE",
      key: "balance",
      render: (item: any) => (+item.balance).toLocaleString(getLocaleKey(), { maximumFractionDigits: 4, minimumFractionDigits: 4 }),
    },
    {
      name: "ACTIONS",
      render: (item: any, fetchData: any) => {
        return (
          <div className="d-flex align-items-center">
            <Button type="button" buttonType="primary" label="Transfer" onClick={() => setBankAccountFrom(item)} />

            <Button
              className="ml10"
              type="button"
              buttonType="success"
              label="Matching"
              onClick={() => {
                setDataMatchingBank(item);
              }}
            />
          </div>
        );
      },
    },
  ];

  // const handleExportExcel = async () => {
  //     return new Promise(async (resolve) => {
  //         try {
  //             const response = await AdminService.getListWithdrawProgress({
  //               page: 1,
  //               numberOfItemsPerPage: 10000,
  //               ...params,
  //             });

  //             const data = response.data;

  //             const fileHead = structure.map((v) => v.name);
  //             const dataExport = [
  //                 fileHead,
  //                 ...data.map((item: any) =>
  //                     structure.map((column, index) => {
  //                         if (!column.key) return "";
  //                         if (column.key==='coinId') {
  //                           const coin = coinsOptions.find((v: any) => v.value === item.coinId);
  //                           return `${ObjectUtils.getIn(coin, "label", "--")} (${item.network})`;
  //                         };
  //                         if (column.key==='created') return moment(item[column.key]).format("L HH:mm:ss");
  //                         if (column.key==='value') {
  //                           return (+item.value).toLocaleString(getLocaleKey(), { maximumFractionDigits: 4, minimumFractionDigits: 4 })
  //                         };
  //                         if (column.key==='valueWithoutFee') {
  //                           return (+item.valueWithoutFee).toLocaleString(getLocaleKey(), { maximumFractionDigits: 4, minimumFractionDigits: 4 })
  //                         };
  //                         return item[column.key];
  //                     })
  //                 ),
  //             ];

  //             const ws = XLSX.utils.aoa_to_sheet(dataExport);
  //             const wb = XLSX.utils.book_new();
  //             XLSX.utils.book_append_sheet(wb, ws, "SheetJS");

  //             const now = new Date();
  //             XLSX.writeFile(
  //                 wb,
  //                 `DIG Progress ${now
  //                     .toLocaleDateString()
  //                     .replace(/\//g, "-")} ${now
  //                     .toLocaleTimeString()
  //                     .replace(/:/g, "-")}.xlsx`,
  //                 { type: "binary" }
  //             );

  //             resolve(
  //                 CreateAlert({
  //                     type: "success",
  //                     message: "Export data success.",
  //                 })
  //             );
  //         } catch (error:any) {
  //             resolve(
  //                 CreateAlert({ type: "danger", message: error.message })
  //             );
  //         }
  //     });
  // };

  useEffect(() => {
    BankService.getBankAccountNoneAvailable().then((res) => {
      setBankList(
        res?.map((item: any) => ({
          label: `${item?.accountHolder} - ${item?.accountNumber}`,
          value: item?.bankAccountId,
        }))
      );
    });
  }, [forceUpdateTable]);

  return (
    <div className="DepositDIG">
      {/* <Button
            className="mb20"
            label="Export to Excel"
            buttonType="success"
            onClick={handleExportExcel}
        /> */}
      <Table
        itemPerPages={10}
        hasOrderColumn
        forceUpdateTable={forceUpdateTable}
        structure={structure}
        fetchData={async (state) => {
          let params = { ...state };

          setParams(params);

          return BankService.getBankAccount({
            ...params,
            page: params.pageNumber,
            pageSize: params.limit,
          });
        }}
      />

      {bankAccountFrom && (
        <PopupWraper
          center
          title="TRANSFER BANK"
          onClose={() => {
            setBankAccountFrom(null);
            setBankAccountTo(null);
            resetForm();
          }}
        >
          <div className="Edit">
            <div className="item">
              <div className="label mb10">Bank From</div>
              <InputSelect
                options={[]}
                value={{}}
                defaultValue={{
                  label: `${bankAccountFrom?.accountHolder} - ${bankAccountFrom?.accountNumber}`,
                  value: bankAccountFrom?.bankAccountId,
                }}
                isDisabled
                name=""
                onChange={() => {}}
                onTouched={() => {}}
              />
            </div>

            <div className="item">
              <div className="label mb10">Bank To</div>
              <InputSelect
                options={bankList.filter((item: any) => item?.value !== bankAccountFrom?.bankAccountId)}
                onChange={(e) => setBankAccountTo(e)}
                onTouched={() => false}
                value={bankAccountTo}
                name=""
              />
            </div>

            <InputWraper label={"Amount"} inputProps={getInputProps("amount")} component={InputNumber} />

            <div>
              <Button
                label="Transfer"
                onClick={handleSubmit}
                isMiddle
                buttonType="success"
                className="btnBan"
                type="submit"
                isLoading={loading}
                disabled={!(bankAccountTo && +getInputProps("amount").value > 0)}
              />
            </div>
          </div>
        </PopupWraper>
      )}

      {dataMatchingBank && (
        <GeneralPopupCompnt
          onClose={() => {
            setDataMatchingBank(null);
          }}
          onClickConfirm={() => {
            setLoading(true);
            return BankService.bankMatching({ bankAccountId: dataMatchingBank?.bankAccountId })
              .then((res) => {
                CreateAlert({
                  type: "success",
                  message: "Matching successfully",
                });
              })
              .catch(onError)
              .finally(() => {
                setDataMatchingBank(null);
                setLoading(false);
              });
          }}
          textButton="OK"
          titlePopup={"Notification!"}
          messagePopup={[
            `Are you sure you want to match the balance of bank `,
            [
              <span style={{ fontWeight: "bold" }}>
                {dataMatchingBank?.accountHolder} - {dataMatchingBank?.accountNumber}
              </span>,
            ],
            `?`,
          ]}
          isLoading={loading}
        ></GeneralPopupCompnt>
      )}
    </div>
  );
});
