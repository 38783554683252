import React, { FC } from "react";
import { NavLink, RouteComponentProps } from "react-router-dom";
import { Icon } from "../../components";
import { Routes } from "../../AppRoutes";

export interface IReportWraperProps extends RouteComponentProps {}

export const withReportWraper = (Component: FC<IReportWraperProps>) => (props: IReportWraperProps) => {
  return (
    <div className="withReportWraper">
      <div className="TabLinks">
        <NavLink to={Routes.agent.path} exact={true}>
          <Icon.Report />
          Report
        </NavLink>

        <NavLink to={Routes.settlement.path} exact={true}>
          <Icon.HistoryIcon />
          Settlement
        </NavLink>
      </div>

      <Component {...props} />
    </div>
  );
};
